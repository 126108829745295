export default {
  CLEAR_TOTENS(state) {
    state.totens = [];
  },
  erase(state) {
    state.totens = [];
    state.menu = false;
  },
  setMenu(state, payload) {
    state.menu = payload ? payload : !state.menu;
  },
  addTotem(state, payload) {
    console.log("addTotem: ", payload);
    state.totens.push(payload);
  },
  updateTotem(state, payload) {
    state.totens.find((item, index) => {
      if (item.id === payload.id) {
        state.totens.splice(index, 1, payload);
      }
    });
  },
  removeTotem(state, TOTEM_ID) {
    state.totens.find((item, index) => {
      if (item.id === TOTEM_ID) {
        state.totens.splice(index, 1);
      }
    });
  },
  setTotens(state, payload) {
    state.totens = payload;
  },
};
