export default {
  getMenu(state) {
    return state.menu;
  },
  getTotens(state) {
    return state.totens;
  },
  getTotemId: (state) => (id) => {
    return state.totens.find((totem) => totem.id == id);
  },
};
