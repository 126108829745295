<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="500px"
      transition="dialog-transition"
    >
      <div
        class="tw-transition-all tw-px-6 sm:tw-px-8 tw-py-2 sm:tw-py-4 tw-mx-auto tw-justify-center tw-self-center tw-bg-white tw-max-w-lg tw-shadow-xl tw-rounded-md"
        :class="getColorForType.border"
      >
        <div
          id="header"
          class="tw-grid tw-grid-cols-4 tw-justify-center tw-align-middle"
        >
          <div
            :class="getColorForType.titulo"
            class="tw-col-span-3 tw-py-2 tw-font-normal tw-uppercase tw-text-md"
          >
            {{ getInfoTitulo }}
          </div>
          <div class="tw-col-span-1 tw-text-right">
            <v-btn icon class="tw--mr-5" @click="toggle()">
              <v-icon size="18">mdi-close</v-icon>
            </v-btn>
          </div>
        </div>
        <div class="tw-py-1 sm:tw-py-3 tw-px-0 tw-text-md tw-text-gray-800">
          {{ mensagem }}
        </div>
        <div id="lista" v-if="lista">
          <div
            id="item"
            :key="index"
            v-for="(item, index) in lista"
            class="tw-p-0 tw-text-sm"
          >
            <v-icon
              v-if="tipo == 'ERROR'"
              class="tw-fill-current tw-text-red-800 tw-mr-2"
              size="10"
              >mdi-block-helper</v-icon
            >
            <v-icon
              v-if="tipo == 'WARNING'"
              class="tw-fill-current tw-text-yellow-500 tw-mr-2"
              size="10"
              >mdi-block-helper</v-icon
            >
            {{ item.mensagem }}
          </div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      mensagem: "",
      tipo: "INFO",
      lista: [],
    };
  },
  watch: {
    getModal: {
      immediate: true,
      deep: true,
      handler: function (val) {
        this.dialog = val.toggle;
        this.mensagem = val.mensagem;
        this.tipo = val.tipo;
        this.lista = val.lista;
      },
    },
  },
  methods: {
    toggle() {
      this.dialog = !this.dialog;
    },
    close() {
      this.$store.commit("setModal", {
        tipo: "INFO",
        toggle: false,
        mensagem: "",
      });
    },
  },
  computed: {
    getModal() {
      return this.$store.getters["getModal"];
    },
    getColorForType() {
      let payload = "";
      switch (this.tipo) {
        case "INFO":
          payload = {
            titulo: "tw-text-blue-600",
            border: "tw-border-blue-600 tw-border-l-8",
          };
          break;
        case "WARNING":
          payload = {
            titulo: "tw-text-yellow-500",
            border: "tw-border-yellow-500 tw-border-l-8",
          };
          break;
        case "ERROR":
          payload = {
            titulo: "tw-text-red-700",
            border: "tw-border-red-700 tw-border-l-8",
          };
          break;
        case "SUCCESS":
          payload = {
            titulo: "tw-text-green-500",
            border: "tw-border-green-500 tw-border-l-8",
          };
          break;
      }

      return {
        titulo: {
          [payload.titulo]: true,
        },
        border: {
          [payload.border]: true,
        },
      };
    },
    getInfoTitulo() {
      let payload = "";
      switch (this.tipo) {
        case "INFO":
          payload = "Informativo";
          break;
        case "WARNING":
          payload = "Atenção";
          break;
        case "ERROR":
          payload = "Ops, algo deu errado...";
          break;
        case "SUCCESS":
          payload = "Sucesso !";
          break;
      }
      return payload;
    },
  },
};
</script>
