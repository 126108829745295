import Vue from "vue";
import moment from "moment";

Vue.filter("CalendarDate", (payload) => {
  var savedState = payload;
  if (payload.seconds && payload.nanoseconds) {
    savedState = payload.toDate();
  }
  return moment(savedState).format("DD/MM/YYYY");
});

Vue.filter("CalendarHora", (payload) => {
  var savedState = payload;
  if (payload.seconds && payload.nanoseconds) {
    savedState = payload.toDate();
  }
  return moment(savedState).format("HH:mm:ss");
});

Vue.filter("ExisteDados", (payload) => {
  if (!payload || payload == "") {
    return "Não definido";
  }
  return payload;
});
