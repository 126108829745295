export default {
  auth: false,
  token: null,
  user: {
    uid: null,
    nome: null,
    role: null,
    franqueado_id: null,
    email: null,
  },
};
