export default {
  erase(state) {
    state.token = null;
    state.auth = false;
    state.user = {
      uid: null,
      nome: null,
      role: null,
      franqueado_id: null,
      email: null,
    };
  },
  setAuth(state, payload) {
    state.auth = payload;
  },
  setToken(state, payload) {
    state.token = payload;
  },
  setUserAuthCode(state, payload) {
    state.user = payload;
  },
  setUserPayload(state, payload) {
    state.user.nome = payload.nome;
    state.user.role = payload.role;
    state.user.franqueado_id = payload.franqueado_id;
    state.user.email = payload.email;
  },
  removeUser(state) {
    state.user = {
      uid: null,
      nome: null,
      role: null,
      franqueado_id: null,
      email: null,
    };
  },
};
