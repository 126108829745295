export default {
  path: "/dashboard",
  name: "Dashboard",
  component: () => import("@/views/dashboard/index.vue"),
  redirect: "/dashboard/home",
  children: [
    {
      path: "home",
      name: "homeDashboard",
      component: () => import("@/views/dashboard/content/home/index.vue"),
    },
    {
      path: "usuarios",
      name: "usuarios",
      component: () => import("@/views/dashboard/content/usuarios"),
    },
    {
      path: "molduras",
      name: "molduras",
      component: () =>
        import("@/views/dashboard/content/molduras/MoldurasRoot.vue"),
    },
    {
      path: "logs",
      name: "logs",
      component: () => import("@/views/dashboard/content/logs"),
    },
    {
      path: "videos",
      name: "videos",
      component: () => import("@/views/dashboard/content/faq"),
    },
    {
      path: "totem",
      name: "totem",
      component: () => import("@/views/dashboard/content/totens"),
      children: [
        {
          path: ":id/live",
          name: "live-totem",
          component: () =>
            import("@/views/dashboard/content/totens/liveTotem/index"),
        },
        {
          path: ":id/compras",
          name: "compras-por-idtotem",
          component: () =>
            import("@/views/dashboard/content/totens/compras/index.vue"),
        },
        {
          path: ":id/config",
          name: "configuracao-totem-id",
          component: () =>
            import("@/views/dashboard/content/totens/config/index.vue"),
        },
        {
          path: ":id/franqueados",
          name: "usuarios-totem",
          component: () =>
            import("@/views/dashboard/content/totens/franqueados/index.vue"),
        },
        {
          path: "lista-totem",
          name: "lista-totem",
          component: () =>
            import("@/views/dashboard/content/totens/TotensIndex.vue"),
        },
        {
          path: "cadastro-totem",
          name: "cadastro-totem",
          component: () =>
            import("@/views/dashboard/content/totens/novoCadastro.vue"),
        },
      ],
    },
    {
      path: "404",
      name: "404",
      component: () => import("@/views/dashboard/content/404"),
    },
    {
      path: "*",
      name: "redir404",
      redirect: "404",
    },
  ],
};
