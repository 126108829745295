import { initializeApp } from "firebase/app";
import {
  getFirestore,
  addDoc,
  setDoc,
  doc,
  collection,
} from "firebase/firestore";
import { getStorage } from "firebase/storage";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { FIREBASE_CHAVE_SERVER } from "@/constants/keys";

const app = initializeApp(FIREBASE_CHAVE_SERVER);
export const auth = getAuth();
export const db = getFirestore(app);
export const storage = getStorage(app);
export const storageMolduras = getStorage(app, "gs://photonow-app-molduras");
export const collectionTotem = collection(db, "totem");

//develop testes
export async function BugFixCompraDoTotemPago(compraID, toggle) {
  //MANTER A SEGURANÇA DE QUE A ALTERAÇÃO SEJA REALMENTE DA COMPRA ATUAL & TOTEM ATUAL;
  const compraRef = doc(db, "compras", compraID);
  //const totemRef = doc(db, "totem", totemID);
  //CompraAlterada.keep_alive = Timestamp.fromDate(new Date());
  //store.commit("compra/setCompra", CompraAlterada);
  await setDoc(
    compraRef,
    {
      pago: toggle,
    },
    { merge: true }
  );
  return;
}

// PADRÃO
export async function createTotem(payload) {
  let request = await addDoc(collectionTotem, payload);
  return request;
}

export async function updateTotem(key, mergeObject) {
  let totemRef = doc(db, "totem", key);
  return await setDoc(totemRef, mergeObject, { merge: true })
    .then(() => {
      return { ok: true, mensagem: "alterado com sucesso" };
    })
    .catch((err) => {
      return { ok: false, mensagem: err };
    });
  //return requestEdit;
}

export function createCabine(email, password) {
  createUserWithEmailAndPassword(auth, email, password).then(
    (userCredential) => {
      //signed in
      const user = userCredential.user;
      console.log(user);
    }
  );
}

export function getCurrentUser() {
  let user = auth.currentUser;
  if (user !== null) {
    return user;
  } else {
    return null;
  }
}

export { createUserWithEmailAndPassword, signInWithEmailAndPassword };
