import Vue from "vue";
import VueRouter from "vue-router";
import vDashboard from "./dashboard.js";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "root",
    redirect: "/login",
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/login.vue"),
  },
  vDashboard,
  {
    path: "*",
    name: "nao-registrada",
    redirect: "/login",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.VUE_BASE_URL,
  routes,
});

export default router;
