import { db } from "@/service/firebase";
import {
  query,
  collection,
  where,
  getDocs,
  getDoc,
  doc,
} from "firebase/firestore";

export default {
  async adminLoadTotens({ commit }) {
    commit("CLEAR_TOTENS");
    let referencia = query(collection(db, "totem"));
    let snapshots = await getDocs(referencia);
    snapshots.forEach((doc) => {
      let payload = doc.data();
      payload.id = doc.id;
      if (!payload.excluido) {
        commit("addTotem", payload);
      }
    });
  },
  async loadTotens({ commit, rootGetters, dispatch }) {
    console.log("loadTotens...");
    let userRole = rootGetters["user/getUser"].role;
    if (userRole.includes("ADMIN")) {
      await dispatch("adminLoadTotens");
      return false;
    }
    commit("CLEAR_TOTENS");
    let userID = rootGetters["user/getToken"];
    let referencia = query(
      collection(db, "userFranqueado"),
      where("userID", "==", `${userID}`)
    );
    var snapshots = await getDocs(referencia);
    snapshots.forEach((doc) => {
      requestTotemID(doc.data().totemID);
    });

    async function requestTotemID(totemID) {
      const docRef = doc(db, "totem", `${totemID}`);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        let payload = docSnap.data();
        payload.id = docSnap.id;
        commit("addTotem", payload);
      } else {
        throw Error("Totem da lista não existe");
      }
    }
  },
};
