import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import "./index.css";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueMask from "v-mask";
import Vuelidate from "vuelidate";
import VueCurrencyFilter from "vue-currency-filter";
import money from "v-money";
import "./scss/index.scss";
import "@/service/firebase.js";
import "./filtrosGlobal";
//import { makeServer } from "./mirage";
/*if (process.env.VUE_APP_ENV === "development") {
  makeServer({ environment: "development" });
}*/
import inputSelect from "@/components/inputSelect";
import VueApexCharts from "vue-apexcharts";
import axios from "axios";
Vue.use(VueApexCharts);
Vue.component("apexchart", VueApexCharts);
Vue.component("input-select", inputSelect);
Vue.use(VueMask);
Vue.use(Vuelidate);
Vue.use(money, {
  decimal: ",",
  thousands: ".",
  prefix: "R$ ",
  suffix: "",
  precision: 2,
  masked: false,
});
Vue.use(VueCurrencyFilter, [
  {
    symbol: "R$",
    thousandsSeparator: ".",
    fractionCount: 2,
    fractionSeparator: ",",
    symbolPosition: "front",
    symbolSpacing: true,
    avoidEmptyDecimals: undefined,
  },
  {
    name: "currencySemDecimal",
    symbol: "R$",
    thousandsSeparator: ".",
    fractionCount: 0,
    fractionSeparator: ",",
    symbolPosition: "front",
    symbolSpacing: true,
    avoidEmptyDecimals: undefined,
  },
]);

if (!String.prototype.includes) {
  String.prototype.includes = function () {
    "use strict";
    return String.prototype.indexOf.apply(this, arguments) !== -1;
  };
}

Vue.config.productionTip = false;

axios.interceptors.request.use(
  function (config) {
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    return Promise.reject(error.response);
  }
);

new Vue({
  router,
  store,
  vuetify,
  data() {
    return {
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        suffix: "",
        precision: 2,
        masked: false,
        handlerTotem: null,
        handlerUser: null,
      },
    };
  },
  methods: {
    goto(routePath) {
      if (this.$route.path == routePath) {
        return;
      } else {
        this.$router.push(routePath);
      }
    },
    mountModal(tipo, msg, lista) {
      this.$store.commit("setModal", {
        toggle: true,
        tipo: tipo,
        mensagem: msg,
        lista: lista ? lista : [],
      });
    },
    sucessoModal(msg, lista) {
      this.mountModal("SUCCESS", msg, lista);
    },
    infoModal(msg, lista) {
      this.mountModal("INFO", msg, lista);
    },
    atencaoModal(msg, lista) {
      this.mountModal("WARNING", msg, lista);
    },
    errorModal(msg, lista) {
      this.mountModal("ERROR", msg, lista);
    },
  },
  render: (h) => h(App),
}).$mount("#app");
