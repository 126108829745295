import Vue from "vue";
import Vuex from "vuex";
import UserModule from "./user/index";
import DashboardModule from "./dashboard/index";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    modal: {
      tipo: "info",
      mensagem: "",
      lista: [],
      toggle: false,
    },
  },
  mutations: {
    setModal(state, payload) {
      state.modal.tipo = payload.tipo ? payload.tipo : "INFO";
      state.modal.mensagem = payload.mensagem ? payload.mensagem : "";
      state.modal.lista = payload.lista ? payload.lista : [];
      state.modal.toggle = payload.toggle ? payload.toggle : false;
    },
  },
  getters: {
    getModal(state) {
      return state.modal;
    },
  },
  modules: {
    user: UserModule,
    dashboard: DashboardModule,
  },
});
