<template>
  <div class="tw-relative">
    <div
      id="shadow"
      class="tw-fixed tw-z-10 tw-bg-black tw-bg-opacity-0 tw-w-screen tw-h-screen tw-top-0 tw-left-0"
      v-if="selectMenu"
      @click="activeMenuSelect()"
    ></div>
    <div
      class="tw-w-full focus:tw-outline-none tw-block tw-appearance-none tw-rounded-md tw-px-4 tw-py-3 tw-border tw-border-gray-200"
      @click="activeMenuSelect()"
      @blur="activeMenuSelect()"
      :class="{ 'tw-bg-gray-50': disabled, ' tw-bg-white': !disabled }"
    >
      <!--<div class="tw-text-gray-100" v-if="!selected">{{ value }}</div>-->
      <div class="tw-text-gray-700 tw-grid tw-grid-cols-3">
        <div class="tw-col-span-2 tw-text-left">
          {{ getSelectName }}
        </div>
        <div
          :class="{
            'tw-text-gray-400': disabled,
            'tw-text-pink-600': !disabled,
          }"
          class="tw-col-span-1 tw-text-right"
        >
          <svg
            v-if="!disabled"
            xmlns="http://www.w3.org/2000/svg"
            class="tw-h-6 tw-w-6 tw-inline-block"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M5 13l4 4L19 7"
            />
          </svg>
          <svg
            v-else
            xmlns="http://www.w3.org/2000/svg"
            class="tw-h-6 tw-w-6 tw-inline-block"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
            />
          </svg>
        </div>
      </div>
    </div>
    <div
      v-if="selectMenu"
      class="tw-absolute tw-w-full tw-mt-1 tw-bg-white tw-z-20 tw-rounded-md tw-shadow-lg tw-py-1 tw-border tw-border-gray-200"
    >
      <div
        :key="index"
        v-for="(item, index) in items"
        class="tw-p-2 tw-cursor-pointer hover:tw-bg-gray-100"
        @click="selectItem(item.id)"
      >
        <div class="tw-grid tw-grid-cols-4">
          <div class="tw-col-span-3 tw-px-2 tw-text-left">{{ item.name }}</div>
          <div class="tw-col-span-1 tw-text-right">
            <div v-if="item.id === selected" class="tw-text-pink-600">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="tw-h-6 tw-w-6 tw-inline-block"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 13l4 4L19 7"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: null,
    disabled: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
  model: {
    prop: "value",
    event: "input",
  },
  data() {
    return {
      selectMenu: false,
      selected: null,
    };
  },
  computed: {
    getSelectName() {
      const item = this.items.find((item) => item.id == this.value);
      return item ? item.name : null;
    },
  },
  mounted() {
    this.selected = this.value
      ? this.value
      : this.items[0].id
      ? this.items[0].id
      : null;
  },
  methods: {
    selectItem(value) {
      this.selected = value;
      this.$emit("input", value);
      this.activeMenuSelect();
    },
    activeMenuSelect() {
      if (!this.disabled) {
        this.selectMenu = !this.selectMenu;
      }
    },
  },
};
</script>
