export default {
  getUser(state) {
    return state.user;
  },
  getToken(state) {
    return state.token;
  },
  getRole(state) {
    return state.user.role[0];
  },
  getUserFranqueado(state) {
    return state.user.franqueado_id;
  },
  getAuth(state) {
    return state.auth;
  },
};
