<template>
  <v-app>
    <v-main>
      <vMensagens />
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { db } from "@/service/firebase";
import vMensagens from "@/components/mensagem";

export default {
  components: {
    vMensagens,
  },
  name: "App",
  data() {
    return {
      loading: true,
    };
  },
  mounted() {
    this.hasAuth();
  },
  methods: {
    hasAuth() {
      const auth = getAuth();
      onAuthStateChanged(auth, (user) => {
        if (user) {
          this.validaExistenciaDoUsuario(user);
        } else {
          this.$store.commit("user/setAuth", false);
        }
      });
    },
    async validaExistenciaDoUsuario(user) {
      const token = user.uid;
      const docRef = doc(db, "users", `${token}`);
      const docSnap = await getDoc(docRef);
      let auth = getAuth();

      if (docSnap.exists()) {
        this.$store.commit("user/setAuth", true);
        this.$store.commit("user/setToken", token);
      } else {
        this.$store.commit("user/setAuth", false);
        signOut(auth)
          .then(() => {
            this.$root.infoModal(
              "Infelizmente você não tem acesso ao dashboard"
            );
          })
          .catch((error) => {
            this.$root.infoModal(
              "Infelizmente você não tem acesso ao dashboard, ocorreu algum erro durante a sua conexão"
            );
            console.log(error);
          });
      }
    },
  },
};
</script>
